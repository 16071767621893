import React from "react";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router";
import "./sections.scss";

const ArticleCard = ({ title, icon, description, categoryId, faqId }) => {
  const history = useHistory();

  const handleClick = () => {
    history.push(`/categories/${categoryId}/${faqId}`);
  };

  return (
    <Col xs={12} md={3} className="text-center p-4">
      <Card
        className="card-sections mx-auto px-4 py-4 text-center"
        onClick={() => {
          handleClick();
        }}
      >
        <FontAwesomeIcon
          icon={icon}
          size="3x"
          className="mx-auto mb-4"
          color="#118df0"
        />
        <h3>{title}</h3>
        <p className="m-0">{description}</p>
      </Card>
    </Col>
  );
};

export default ArticleCard;
