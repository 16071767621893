import {
  couponFaqs,
  rewardFaqs,
  generalFaqs,
  accountFaqs,
  // businessFaqs
} from "./faqs";

const list = [
  {
    title: "Cupones",
    categoryId: "cupones",
    faqs: couponFaqs,
  },
  {
    title: "Promociones",
    categoryId: "promociones",
    faqs: rewardFaqs,
  },
  {
    title: "Plataforma",
    categoryId: "plataforma",
    faqs: generalFaqs,
  },
  {
    title: "Cuenta",
    categoryId: "cuenta",
    faqs: accountFaqs,
  },
  // {
  //   title: "Negocios",
  //   categoryId: "negocios",
  //   faqs: businessFaqs,
  // },
];

export default list;
