import React, { useState, useEffect } from "react";
import { Col, Collapse, Container, InputGroup, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router";
import Form from "react-bootstrap/Form";
import SearchAlgorithm2 from "./searchAlgorithm.js";
import LottieAnim from "react-lottie";
import dots from "../../lottieAssets/dots.json";
import "../navigation/navigation.scss";

const Searchbar = ({ border, className }) => {
  const [searchValue, setSearchValue] = useState("");
  const [open, setOpen] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [sloading, setSloading] = useState(true);
  const [delay, setDelay] = useState(0);
  const history = useHistory();

  const searchFaq = (value) => {
    setSloading(true);
    setOpen(true);
    setDelay(delay + 150);
    var value1 = [];
    if (
      value === "" ||
      value === null ||
      value === undefined ||
      value === " "
    ) {
      setOpen(false);
      setSearchValue("");
      value1 = [];
    } else {
      setSearchValue(value);
      value1 = SearchAlgorithm2(value);
    }

    if (
      value1 === [] ||
      value1 === undefined ||
      value1 === null ||
      value.length < 1
    ) {
      setOpen(false);
      // setSearchValue("");
    } else {
      setSuggestions(value1);
      // setOpen(true);
    }
  };

  useEffect(() => {
    setSloading(true);
    setSloading(false);
    setTimeout(() => {
      setSloading(false);
      setDelay(0);
    }, 3000 + delay);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const LottieDots = () => {
    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: dots,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    return (
      <LottieAnim
        options={defaultOptions}
        className="py-3"
        style={{ width: "70px", height: "60px" }}
      />
    );
  };

  const handleClick = (category, faq, index) => {
    history.push(`/categories/${category}/${faq}/${index}`);
    window.location.reload(false);
  };
  return (
    <Container
      style={{ backgroundColor: "white", borderRadius: "10px" }}
      className="p-0"
    >
      {/* <Form
      onSubmit={performSearch}
      >
         <Form.Group className="mb-3" controlId="formBasicEmail"> */}
      <Col className="p-0">
        <InputGroup
          style={
            border && { borderRadius: "20px", border: "1px solid #dee2e6" }
          }
        >
          <InputGroup.Text type="submit">
            <FontAwesomeIcon icon={faSearch} />
          </InputGroup.Text>

          <Form.Control
            value={searchValue}
            onChange={(e) => {
              searchFaq(e.target.value);
            }}
            type="text"
            autocomplete="off"
            placeholder="Describe el problema... (p. ej. cupones, recompensas, promociones, etc.)"
          />
        </InputGroup>
        <Collapse
          in={open}
          // className={className}
        >
          <div className="w-100 border-top">
            {sloading ? (
              <LottieDots />
            ) : suggestions.length >= 1 ? (
              suggestions.map((element, index) => (
                <Button
                  className="suggestion"
                  key={index}
                  onClick={(e) => {
                    e.preventDefault();
                    handleClick(
                      element.categoryId,
                      element.faqId,
                      element.index
                    );
                  }}
                >
                  {element.question}
                </Button>
              ))
            ) : open ? (
              <p
                className="py-3 m-0"
                style={{
                  fontFamily: "Poppins, sans-serif",
                }}
              >
                Sin resultados
              </p>
            ) : (
              <div />
            )}
          </div>
        </Collapse>
      </Col>
      {/* </Form.Group> */}

      {/* </Form> */}
    </Container>
  );
};

export default Searchbar;
