import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import { useHistory } from "react-router";

const Faq = ({ question, answer, categoryId, faqId, index }) => {
  const history = useHistory();

  const handleClick = (category, faq, index) => {
    history.push(`/categories/${category}/${faq}/${index}`);
  };
  return (
    <Col
      xs={12}
      md={3}
      className="py-1 mb-3"
      // onClick={() => {
      //   setOpen(!open);
      // }}
    >
      <Button
        variant="outline-primary"
        style={{
          width: "100%",
          display: "block",
          fontFamily: "Poppins, sans-serif",
        }}
        onClick={(e) => {
          e.preventDefault();
          handleClick(categoryId, faqId, index);
        }}
      >
        {question}
      </Button>

      {/* <Collapse in={open}>
        <Card>
          <Card.Body>
            <p className="m-0 p-0 text-answer">{answer}</p>
          </Card.Body>
        </Card>
      </Collapse> */}
    </Col>
  );
};

export default Faq;
