import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import sections from "../../data/sections";
import ArticleCard from "./ArticleCard";

const Sections = () => {
  return (
    <Container className="p-2 my-4">
      <Row className="justify-content-center text-center">
        {sections.map((section) => {
          const { title, categoryId } = section;
          return (
            <div className="my-4">
              <h2 className="mb-4">{title}</h2>
              <Row className="justify-content-center text-center">
                {section.faqs.map((faq) => {
                  return (
                    <ArticleCard
                      {...faq}
                      categoryId={categoryId}
                      key={categoryId}
                    />
                  );
                })}
              </Row>
            </div>
          );
        })}
      </Row>
    </Container>
  );
};

export default Sections;
