import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Landing from "../sections/landing/Landing.js";
import Categories from "../sections/categories/Categories.js";
import "../sections/landing/landing.scss";
import Question from "../sections/questionPage.js/Question.js";

const MainLayout = () => {
  return (
    <Router fallback={<span />}>
      <Switch>
        <Route path="/" exact component={Landing} />
        <Route
          path={["/categories/:categoryId/:faqId","/categories/:categoryId"]}
          exact
          component={Categories}
        />
        <Route
          path="/categories/:categoryId/:faqId/:index"
          exact
          component={Question}
        />
      </Switch>
    </Router>
  );
};

export default MainLayout;
