import React, { useState, useEffect } from "react";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Container from "react-bootstrap/Container";
import NavbarMain from "../../components/navigation/Navbar";
import Searchbar from "../../components/search/Searchbar";
import "./question.scss";
import faqs from "../../data/faqs";
import list from "../../data/sections";
import LottieAnim from "react-lottie";
import dots from "../../lottieAssets/dots.json";
import { useHistory } from "react-router";
import { Button, Col, Row } from "react-bootstrap";
import Footer from "../../components/navigation/Footer";

const LottieDots = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: dots,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <LottieAnim
      options={defaultOptions}
      style={{ width: "70px", height: "50px" }}
    />
  );
};

const RelatedAlgorithm = (data) => {
  var searchWords = data;

  var rawQuestions = [];
  function QuestionObject(
    question,
    answer,
    keywords,
    index,
    categoryId,
    faqId,
    count
  ) {
    this.question = question;
    this.answer = answer;
    this.keywords = keywords;
    this.index = index;
    this.categoryId = categoryId;
    this.faqId = faqId;
    this.count = count === undefined ? 0 : count;
  }

  faqs.forEach((macro, macroind) => {
    macro.forEach((category, categoind) => {
      category.questions.forEach((question, i) => {
        rawQuestions.push(
          new QuestionObject(
            question.question,
            question.answer,
            question.keywords,
            i,
            list[macroind].categoryId,
            faqs[macroind][categoind].faqId
          )
        );
      });
    });
  });

  // console.log('questions: ', rawQuestions);

  var wordCount = 0;
  var responseArray = [];

  rawQuestions.forEach((question, questInd) => {
    searchWords.forEach((word) => {
      if (question.keywords.includes(word)) {
        wordCount = wordCount + 1;
      }
    });
    // var obj = new QuestionObject(question.question, question.answer, wordCount, question.index);
    rawQuestions[questInd].count = wordCount;
    if (rawQuestions[questInd].count > 0) {
      responseArray.push(rawQuestions[questInd]);
    }
    wordCount = 0;
  });

  responseArray.sort(function (a, b) {
    if (a.count < b.count) {
      return 1;
    }
    if (a.count > b.count) {
      return -1;
    }
    // a must be equal to b
    return 0;
  });
  console.log(responseArray);

  return responseArray.slice(1, 6);
};

const Question = ({ match: { params } }) => {
  const { categoryId } = params;
  const { faqId } = params;
  const { index } = params;
  const [question, setQuestion] = useState([]);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const [related, setRelated] = useState([]);

  var locations = [];
  locations = history.location.pathname.split("/");

  useEffect(() => {
    var passed = [];
    setLoading(true);
    list.forEach((cat, ind) => {
      // console.log('catInd: ',cat.categoryId);
      if (cat.categoryId === categoryId) {
        passed.push(ind);
      }
    });

    if (passed.length === 1) {
      faqs[passed[0]].forEach((child, i) => {
        if (child.faqId === faqId) {
          passed.push(i);
        }
      });
    }

    if (passed.length === 2) {
      // console.log(faqs[passed[0]][passed[1]]);
      setQuestion(faqs[passed[0]][passed[1]].questions[index]);
      setRelated(
        RelatedAlgorithm(faqs[passed[0]][passed[1]].questions[index].keywords)
      );
      // console.log(question);
      setLoading(false);
    }
  }, [categoryId, faqId, index]);

  const handleClick = (category, faq, index) => {
    history.push(`/categories/${category}/${faq}/${index}`);
  };
  return (
    <div
      className="d-flex flex-column"
      style={{
        height: "100vh",
        display: "flex",
        direction: "column",
        justifyContent: "space-between",
      }}
    >
      <div>
        <NavbarMain />
        <Container className="py-2">
          <Searchbar border className="mb-4" />
          <div className="mt-4">
            <Breadcrumb>
              <Breadcrumb.Item className="blink" href="/">
                Inicio
              </Breadcrumb.Item>
              <Breadcrumb.Item
                className="blink"
                href={`/categories/${locations[2]}`}
              >
                {locations[2].charAt(0).toUpperCase() + locations[2].slice(1)}
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                {loading ? (
                  <LottieDots />
                ) : question.question.length > 35 ? (
                  question.question.slice(0, 35) + "..."
                ) : (
                  question.question
                )}
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
          {loading ? (
            <LottieDots />
          ) : (
            <div>
              <h4 className="mt-3 text-answer">{question.question}</h4>
              <Row>
                <Col md={8}>
                  <div className="mt-3 mb-4 border-top">
                    <div
                      className="mt-4 text-answer"
                      dangerouslySetInnerHTML={{ __html: question.answer }}
                    ></div>
                  </div>
                </Col>

                {related.length > 0 ? (
                  <Col md={4} className="mt-3">
                    <Container className="border">
                      <h5 className="mt-4">Relacionado</h5>
                      <ul className="mb-4">
                        {related.map((suggestion) => (
                          <li>
                            <Button
                              className="related"
                              onClick={(e) => {
                                e.preventDefault();
                                handleClick(
                                  suggestion.categoryId,
                                  suggestion.faqId,
                                  suggestion.index
                                );
                              }}
                            >
                              {suggestion.question}
                            </Button>
                          </li>
                        ))}
                      </ul>
                    </Container>
                  </Col>
                ) : (
                  <div />
                )}
              </Row>
            </div>
          )}

          {/* <Tabs
          id="controlled-tabs"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="mb-3"
        >
          {sections.map((section) => {
            const { categoryId, title } = section;
            return (
              <Tab eventKey={categoryId} title={title}>
                <div className="py-4">
                  {section.faqs.map((faq) => {
                    if (faq.faqId == faqId) {
                      return <Faq {...faq} blue />;
                    } else {
                      return <Faq {...faq} />;
                    }

                  })}
                </div>
              </Tab>
            );
          })}
        </Tabs> */}
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default Question;
