import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import NavbarMain from "../../components/navigation/Navbar";
import Sections from "../sections/Sections";
import Searchbar from "../../components/search/Searchbar";
import Footer from "../../components/navigation/Footer";

const Landing = () => {
  return (
    <>
      <NavbarMain />
      <Container
        fluid
        className="d-flex align-items-center justify-content-center h-100 search-bar-container"
      >
        <Container>
          <Row className="text-center">
            <Col xs={12} className="my-3">
              <h1 className="search-title">¿Cómo podemos ayudarte?</h1>
            </Col>
            <Col xs={12} className="py-3">
              <Searchbar />
            </Col>
          </Row>
        </Container>
      </Container>
      <Sections />
      <Footer />
    </>
  );
};

export default Landing;
