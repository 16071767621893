import React, { useState, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import Container from "react-bootstrap/Container";
import NavbarMain from "../../components/navigation/Navbar";
import Searchbar from "../../components/search/Searchbar";
import sections from "../../data/sections";
import "./categories.scss";
import Faq from "./Faq";
import { scroller } from "react-scroll";
import { useHistory } from "react-router";
import Footer from "../../components/navigation/Footer";

const Categories = ({ match: { params } }) => {
  const [key, setKey] = useState("home");
  const { categoryId } = params;
  const { faqId } = params;
  const history = useHistory();

  var locations = [];
  locations = history.location.pathname.split("/");

  const scrollTo = (e) => {
    scroller.scrollTo(e, {
      // smooth: true
    });
  };
  useEffect(() => {
    if (categoryId !== undefined) {
      setKey(categoryId);
    }

    setTimeout(() => {
      if (faqId !== undefined) {
        scrollTo(faqId);
      }
    }, 500);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categoryId]);

  return (
    <div
      className="d-flex flex-column justify-content-between"
      style={{
        height: "100vh",
        display: "flex",
        direction: "column",
        justifyContent: "space-between",
      }}
    >
      <div>
        <NavbarMain />
        <Container className="py-2">
          <Searchbar border className="mb-4" />

          <Breadcrumb className="mt-4">
            <Breadcrumb.Item className="blink" href="/">
              Inicio
            </Breadcrumb.Item>
            <Breadcrumb.Item active>
              {locations[2].charAt(0).toUpperCase() + locations[2].slice(1)}
            </Breadcrumb.Item>
          </Breadcrumb>
          <Tabs
            id="controlled-tabs"
            activeKey={key}
            onSelect={(k) => {
              setKey(k);
              history.push(`/categories/${k}`);
            }}
            className="mb-3"
          >
            {sections.map((section) => {
              const { categoryId, title } = section;
              return (
                <Tab eventKey={categoryId} title={title}>
                  <div className="py-4">
                    {section.faqs.map((faq) => {
                      // if (faq.faqId == faqId) {
                      //   return <Faq {...faq} blue />;
                      // } else {
                      return <Faq {...faq} categoryId={categoryId} />;
                      // }
                    })}
                  </div>
                </Tab>
              );
            })}
          </Tabs>
        </Container>
      </div>
      <Footer />
    </div>
  );
};

export default Categories;
