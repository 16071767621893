import React from "react";
import FaqQuestion from "./FaqQuestion";
import Row from "react-bootstrap/Row";

const Faq = ({ title, icon, description, faqId, questions,categoryId,index, blue }) => {

  return (
    <div className="py-3">
      {blue ? <h5 className="mb-0" style={{color: "#118df0", fontFamily:"Poppins, sans-serif"}} id={faqId}>{title}</h5> : <h5 className="mb-0" id={faqId} >{title}</h5>}
      
      <hr className="mt-2" />
      <Row>
        {questions.map((question,i) => {
          return <FaqQuestion {...question} categoryId={categoryId} faqId={faqId} index={i} />;
        })}
      </Row>
    </div>
  );
};

export default Faq;
