import {
  faCheckCircle,
  faTicketAlt,
  faGiftCard,
  faList,
  faPercent,
  faMobileAlt,
  faQuestion,
  faUserPlus,
  faUserCircle,
  faExclamationTriangle,
  //faStoreAlt,
} from "@fortawesome/pro-light-svg-icons";

export const couponFaqs = [
  {
    title: "Canjeo",
    icon: faCheckCircle,
    description: "Preguntas frecuentes sobre el canjeo de cupones.",
    questions: [
      {
        question: "¿Cómo canjeo un cupón?",
        answer:
          '<p>Para canjear un cupón, ingresa a <a class="related" href="https://swirvle.com">swirvle.com</a> dale click al negocio del que es el cupón que quieres canjear, busca el cupón y si lo tienes, dale click al botón de "Usar Cupón". Te aparecerá un código QR, ese código es del cupón, muéstraselo a la persona del negocio y listo.</p> <br/> <iframe width="100%" height="315" src="https://www.youtube.com/embed/QgTLstiUmXA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> ',
        keywords: ["canjeo", "canjear", "cupones", "cupón", "cupon"],
      },

      {
        question:
          "¿Si canejo un cupón, afecta en mis demas recompensas / promociones?",
        answer:
          "<p>No, los cupones son independientes a las promociones. En los negocios que tengan habilitado el borrar las visitas o puntos al canjear una promoción, el canjear un cupón no afectará ni hará que se te borren las visitas o puntos, porque son independientes.</p>",
        keywords: [
          "afecta",
          "promociones",
          "recompensas",
          "visitas",
          "canjeo",
          "canjear",
          "borraron",
          "cupones",
          "cupón",
          "cupon",
        ],
      },
    ],
    faqId: "canjeo-cupones",
  },

  {
    title: "Obtención",
    icon: faGiftCard,
    description: "Preguntas frecuentes sobre como se obtiene un cupón.",
    questions: [
      {
        question: "¿Cómo consigo un cupón?",
        answer:
          '<p>Los cupones los otorgan los negocios afiliados a Swirvle. Ellos son quienes deciden en base a ciertos parámetros a quienes enviárselos. Para ver si tu recibiste algún cupón, ingresa a <a href="https://swirvle.com" target="__blank" class="related">swirvle.com</a> y ve a la sección de "Mis Promociones" dentro del negocio quie quieres ver, si el cupón aparece ahí, ¡Felicidades, tienes un cupón! </p>',
        keywords: [
          "obtengo",
          "obtener",
          "consigo",
          "gano",
          "ganar",
          "conseguir",
          "cupones",
          "cupón",
          "cupon",
        ],
      },
    ],
    faqId: "consigo-cupones",
  },
  {
    title: "General",
    icon: faTicketAlt,
    description: "Preguntas generales sobre cupones.",
    questions: [
      {
        question: "¿Cual es la fecha de expiración del cupón?",
        answer:
          "<p>La fecha de exiparación de los cupones aparece en el cupón del lado izquierdo (al lado del botón para canjearlo).</p>",
        keywords: [
          "expira",
          "expiracion",
          "expiración",
          "fecha",
          "cupones",
          "cupón",
          "cupon",
        ],
      },

      {
        question: "¿Cómo se si el cupón sigue disponible?",
        answer:
          '<p>Mientras el cupón aparezca en la sección de "Mis Promociones" dentro del negocio en Swirvle, el cupón está disponible.</p>',
        keywords: [
          "disponible",
          "sigue",
          "hábil",
          "habil",
          "cupones",
          "cupón",
          "cupon",
        ],
      },

      {
        question: "¿Por que desapareció un cupón que tenía?",
        answer:
          "<p>Esto pudo haber pasado por dos razones: </p> <ol> <li>El cupón expiró</li> <li>El cupón se agotó</li></ol>",
        keywords: [
          "disponible",
          "sigue",
          "hábil",
          "habil",
          "cupones",
          "cupón",
          "cupon",
        ],
      },
    ],
    faqId: "general-cupones",
  },
];

export const rewardFaqs = [
  {
    title: "Tipos de Promociónes",
    icon: faList,
    description: "Aprende sobre los varios tipos de promociones con Swirvle.",
    questions: [
      {
        question: "¿Cuales son los tipos de promociones?",
        answer:
          "<p>Existen varios tipos de promociones en las que puedes participar, las cuales son: </p> <ol> <li>Por puntos</li> <li>Por visitas</li> <li>Por compras</li></ol>",
        keywords: [
          "tipos",
          "cuales",
          "cual",
          "formas",
          "promocion",
          "promoción",
          "promociones",
          "recompensa",
          "recompensas",
        ],
      },

      {
        question: "¿Cómo funcionan las promociones por visitas?",
        answer:
          "<p>En las promociones por visitas, tendrás que acumular un cierto numero de visitas con cierto monto de compra mínima para poder desbloquear cierta promoción.</p>",
        keywords: [
          "visitas",
          "funciona",
          "promocion",
          "promoción",
          "promociones",
          "recompensa",
          "recompensas",
        ],
      },

      {
        question: "¿Cómo funcionan las promociones por compra de productos?",
        answer:
          "<p>En las promociones por compras, tendrás que acumular cierto monto de compras de cierto producto establecido por el negocio para poder desbloquear cierta promoción.</p>",
        keywords: [
          "producto",
          "funciona",
          "promocion",
          "promoción",
          "promociones",
          "recompensa",
          "recompensas",
        ],
      },

      {
        question: "¿Cómo funcionan las promociones por puntos?",
        answer:
          "<p>En las promociones por puntos, tendrás que acumular cierto monto de puntos para desbloquear cierta promoción.</p>",
        keywords: [
          "puntos",
          "funciona",
          "promocion",
          "promoción",
          "promociones",
          "recompensa",
          "recompensas",
        ],
      },
    ],
    faqId: "tipos-promociones",
  },
  {
    title: "Canjeo",
    icon: faCheckCircle,
    description: "Preguntas frecuentes sobre el canjeo de promociones.",
    questions: [
      {
        question: "¿Cómo canjeo una promoción?",
        answer:
          '<p>Para canjear una promoción, ingresa a <a class="related" href="https://swirvle.com">swirvle.com</a> dale click al negocio del que es la promoción o cupón que quieres canjear, busca la promoción o cupón y si está desbloqueada dale click al botón de "Usar Promoción" o "Usar Cupón". Te aparecerá un código QR, ese código es de la promoción, muéstraselo a la persona del negocio y listo.</p> <br/> <iframe width="100%" height="315" src="https://www.youtube.com/embed/QgTLstiUmXA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> ',
        keywords: [
          "canjeo",
          "canjear",
          "valida",
          "válida",
          "promocion",
          "promoción",
          "promociones",
          "recompensa",
          "recompensas",
        ],
      },

      {
        question:
          "¿Por qué se me borraron las visitas al canjear una promoción?",
        answer:
          '<p>Cada negocio maneja su marketing a su manera. En algunos negocios, si es que lo deciden así, a la hora de canjear una promoción por visitas, se te restablecerán tus visitas a 0. Comoquiera, se te hará saber en la sección de "Mis Promociones" de cada negocio con un texto en la parte superior.</p>',
        keywords: [
          "borraron",
          "borran",
          "visitas",
          "promocion",
          "promoción",
          "promociones",
          "recompensa",
          "recompensas",
        ],
      },

      {
        question:
          "¿Por qué se me borraron todos los puntos al canjear una promoción?",
        answer:
          '<p>Cada negocio maneja su marketing a su manera. En algunos negocios, si es que lo deciden así, a la hora de canjear una promoción por puntos, se te restablecerán tus puntos a 0. Esto se te hará saber en la sección de "Mis Promociones" de cada negocio con un texto en la parte superior. </p>',
        keywords: [
          "borraron",
          "borran",
          "borra",
          "quitan",
          "puntos",
          "promocion",
          "promoción",
          "promociones",
          "recompensa",
          "recompensas",
        ],
      },
    ],
    faqId: "canjeo-promociones",
  },
  {
    title: "General",
    icon: faPercent,
    description: "Preguntas generales sobre las promociones.",
    questions: [
      {
        question: "¿Puedo canjear la promocion de un negocio en otro negocio?",
        answer:
          '<p>No, cada negocio tiene sus promociones. A la hora de canjear una promoción, podrás canjear solamente las promociones que ese negocio en específico tiene. Es decir, no puedes canjear una promoción del "Negocio A" en el "Negocio B".</p>',
        keywords: [
          "negocio",
          "otro",
          "otros",
          "canjear",
          "negocios",
          "promocion",
          "promoción",
          "promociones",
          "recompensa",
          "recompensas",
          "entre",
        ],
      },
    ],
    faqId: "general-promociones",
  },
];

export const generalFaqs = [
  {
    title: "Cómo participar",
    icon: faUserPlus,
    description: "Aprende sobre los varios tipos de promociones con Swirvle.",
    questions: [
      {
        question: "¿Cómo usar Swirvle?",
        answer:
          '<p>¡No te pierdas de promociones exlusivas! Es sencillo empezar a usar Swirvle. Solamente ingresa a <a class="related" href="https://swirvle.com">swirvle.com</a>, regístrate y muestra tu código QR en los establecimientos afiliados.</p> <br/> <iframe width="100%" height="315" src="https://www.youtube.com/embed/TNhUkYmPEmw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>',
        keywords: [
          "participo",
          "uso",
          "usar",
          "swirvle",
          "mi",
          "qr",
          "muestro",
          "mostrar",
        ],
      },
    ],
    faqId: "como-swirvle",
  },
  {
    title: "App",
    icon: faMobileAlt,
    description: "Preguntas frecuentes sobre el canjeo de promociones.",
    questions: [
      {
        question: "¿Cómo descargar la app?",
        answer:
          '<p>Estamos trabajando en la app para IOS y Android, por lo tanto todavía no está disponible. Por el momento puedes participar en <a class="related" href="https://swirvle.com">swirvle.com</a></p>',
        keywords: [
          "app",
          "descargo",
          "descargar",
          "ios",
          "android",
          "aplicacion",
          "aplicación",
        ],
      },
    ],
    faqId: "app",
  },
  {
    title: "Preguntas comunes",
    icon: faQuestion,
    description: "Algunas preguntas comunes sobre la plataforma.",
    questions: [
      {
        question: "¿Los puntos / visitas cuentan en todos los negocios?",
        answer:
          '<p>Las promociones, cupones, puntos, compras, visitas y demás son POR negocio. Es decir, las promociones de "Negocio A" no las podrás canjear en "Negocio B".</p> <p>Sin embargo, NO tienes que hacer varias cuentas para cada negocio, el código QR de tu cuenta (NO EL DE LAS PROMOCIONES) es el mismo que mostrarás en todos los negocios afiliados con Swirvle.</p>',
        keywords: [
          "todos",
          "negocios",
          "puntos",
          "visitas",
          "valen",
          "cuentan",
        ],
      },
    ],
    faqId: "preguntas-comunes",
  },

  {
    title: "Problemas comunes",
    icon: faExclamationTriangle,
    description: "¿Cómo solucionar problemas comunes?",
    questions: [
      {
        question: "No se puede leer mi código QR.",
        answer:
          "<p>Si no se puede escanear tu código QR, puede ser por alguna (o varias) de las siguientes razones:</p> <ul><li>La pantalla del celular está sucia o quebrada.</li> <li>La pantalla del celular tiene mucho o poco brillo, tendrás que subir o bajar el brillo de pantalla. </li> <li>El dispositivo del negocio puede tener problemas de conexión a internet</li> </ul>",
        keywords: ["leer", "lee", "le", "qr", "mi", "código", "codigo"],
      },

      {
        question: "No se registró mi visita, pero si se escaneo mi código.",
        answer:
          "<p>En algunas ocasiones puede que si se escané tu código QR pero tu compra no cumple con los parámetros mínimos de visita o compras de producto, por lo tanto en esa compra, no participaras en ninguna promoción porque no aplica.</p>",
        keywords: [
          "registro",
          "registró",
          "visita",
          "escaneo",
          "mi",
          "visita",
          "codigo",
          "leer",
          "lee",
          "le",
          "qr",
          "mi",
          "visita",
        ],
      },
    ],
    faqId: "problemas-comunes",
  },
];

export const accountFaqs = [
  {
    title: "Cuenta",
    icon: faUserCircle,
    description: "Preguntas frecuentes sobre tu cuenta de Swirvle",
    questions: [
      {
        question: "¿Mi código QR es el mismo para todos los negocios?",
        answer:
          "<p>Así es, el código QR de tu cuenta, es el que muestras en TODOS los negocios afiliados con Swirvle. Sin embargo, los códigos QR de promociones y/o cupones, son válidos solamente en la(s) sucursales del negocio del que es la promoción o cupón.</p>",
        keywords: [
          "qr",
          "en",
          "mismo",
          "todos",
          "negocios",
          "cuenta",
          "funciona",
          "cualquier",
        ],
      },
    ],
    faqId: "cuenta",
  },
];

// export const businessFaqs = [
//   {
//     title: "Afiliación",
//     icon: faStoreAlt,
//     description: "Preguntas frecuentes sobre el canjeo de cupones.",
//     questions: [
//       {
//         question: "¿Cómo afilio mi negocio?",
//         answer:
//           '<p>Para afiliar tu negocio, por favor visítanos en nuestra página web para negocios. Ahí podrás encontrar toda la información acerca de los beneficios de afiliarte, asi como para ya afiliarte.</p><p>Visítanos en <a href="https://business.swirvle.com" target="__blank" class="related">business.swirvle.com</a></p>',
//         keywords: [
//           "uno",
//           "afilio",
//           "unir",
//           "contratar",
//           "servicio",
//           "plataforma",
//           "afiliar",
//           "negocio",
//           "empresa",
//           "franquicia",
//           "marca",
//           "registro",
//           "registrar",
//         ],
//       },
//     ],
//     faqId: "afiliados",
//   },
// ];

const faqs = [
  couponFaqs,
  rewardFaqs,
  generalFaqs,
  accountFaqs,
  //  businessFaqs
];

export default faqs;
