import faqs from "../../data/faqs";
import list from "../../data/sections";

const SearchAlgorithm2 = (data) => {
  var searchWords = [];
  searchWords = data.toLowerCase().split(" ");

  var rawQuestions = [];
  function QuestionObject(
    question,
    answer,
    keywords,
    index,
    categoryId,
    faqId,
    count
  ) {
    this.question = question;
    this.answer = answer;
    this.keywords = keywords;
    this.index = index;
    this.categoryId = categoryId;
    this.faqId = faqId;
    this.count = count === undefined ? 0 : count;
  }

  faqs.forEach((macro, macroind) => {
    macro.forEach((category, categoind) => {
      category.questions.forEach((question, i) => {
        rawQuestions.push(
          new QuestionObject(
            question.question,
            question.answer,
            question.keywords,
            i,
            list[macroind].categoryId,
            faqs[macroind][categoind].faqId
          )
        );
      });
    });
  });

  // console.log('questions: ', rawQuestions);

  var wordCount = 0;

  var responseArray = [];

  rawQuestions.forEach((question, questInd) => {
    searchWords.forEach((word) => {
      question.keywords.forEach((keyword) => {
        if (keyword.includes(word)) {
          wordCount = wordCount + 1;
        }
      });
    });
    // var obj = new QuestionObject(question.question, question.answer, wordCount, question.index);
    rawQuestions[questInd].count = wordCount;
    if (rawQuestions[questInd].count > 0) {
      responseArray.push(rawQuestions[questInd]);
    }
    wordCount = 0;
  });

  responseArray.sort(function (a, b) {
    if (a.count < b.count) {
      return 1;
    }
    if (a.count > b.count) {
      return -1;
    }
    // a must be equal to b
    return 0;
  });
  console.log(responseArray);

  return responseArray.slice(0, 6);
};

export default SearchAlgorithm2;
