import React from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SwirvleLogo from "../../img/darkB_logo.svg";
import "./navigation.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// import {
//   faFacebook,
// } from "@fortawesome/pro-light-svg-icons";

import {
  faFacebookSquare,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <Container fluid className="footer-container">
      <Container className="py-3 h-100 footer-content">
        <Row className="align-items-center">
          <Col xs={12} md={6} className="text-center">
            <Navbar.Brand href="/">
              <img src={SwirvleLogo} height="45" width="auto" alt="logo" />
            </Navbar.Brand>
          </Col>
          <Col xs={12} md={6}>
            <Row className="align-items-center">
              <Col xs={6} className="mt-3 mb-3 mt-xs-2">
                <h5 className="m-0 mb-2 text-white">Compañía</h5>
                <ul style={{ listStyleType: "none", padding: "0px" }}>
                  <li>
                    <a
                      href="https://firebasestorage.googleapis.com/v0/b/qr-rewards.appspot.com/o/docs%2FAviso%20de%20Privacidad%20Integral%20Swirvle.pdf?alt=media&token=ff324a18-217d-4986-bef8-6028dc57084f"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Política de Privacidad
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://firebasestorage.googleapis.com/v0/b/qr-rewards.appspot.com/o/docs%2FTerminos%20y%20Condiciones%20SWIRVLE.pdf?alt=media&token=b77a00d5-47ab-4a2b-bdc7-7bbe0fcda3b6"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terminos y Condiciones
                    </a>
                  </li>
                </ul>
              </Col>
              <Col xs={6} className="mt-3 mb-3">
                <Row noGutters>
                  <Col xs={12}>
                    <h5 className="m-0 mb-2 text-white">Social</h5>
                    <ul style={{ listStyleType: "none", padding: "0px" }}>
                      <li>
                        <a
                          href="https://www.facebook.com/Swirvle-112375537249581"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FontAwesomeIcon
                            icon={faFacebookSquare}
                            size="2x"
                            className="mx-auto mb-4"
                            color="white"
                          />
                        </a>

                        <a
                          href="https://www.youtube.com/channel/UCZTi4WLPPmUVtFkoGhVY2wQ"
                          target="_blank"
                          rel="noopener noreferrer"
                          className="ml-4"
                          style={{ marginLeft: "8px" }}
                        >
                          <FontAwesomeIcon
                            icon={faYoutube}
                            size="2x"
                            className="mx-auto mb-4"
                            color="white"
                          />
                        </a>

                        <a
                          href="https://instagram.com/swirvle?igshid=uwluhyruq9aw"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ marginLeft: "8px" }}
                        >
                          <FontAwesomeIcon
                            icon={faInstagram}
                            size="2x"
                            className="mx-auto mb-4"
                            color="white"
                          />
                        </a>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col xs={12} lg={12} className="px-3 text-center mt-4">
            <p className="m-0 copyright-text">
              {new Date().getFullYear()} © Swirvle
            </p>
          </Col>
        </Row>
      </Container>
    </Container>
  );
};

export default Footer;
